import React from 'react';
import {Box,Typography,IconButton} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
const IncludedList = ({data})=>{
    return(
        <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <IconButton
                disableRipple
                sx={{
                    // backgroundColor: "red",
                    cursor: "default",
                    height: "22px",
                    width: "22px"
                }}
            >
                <CheckIcon />
            </IconButton>
            <Typography
            variant='subtitle1'
                sx={{
                    fontSize: {xs:"14px",sm:"18px"},
                }}
            >
                {data}
            </Typography>
        </Box>
    )
}
export default IncludedList;