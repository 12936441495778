import React from "react";
import { Box, Typography, IconButton, Container } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './footer.css';
import { Link } from "react-router-dom";
import logo from "../../../assets/sat_app_logo1.png";
const socialMediaIcons = [
  { component: FacebookIcon, label: 'Facebook' },
  { component: XIcon, label: 'X (Twitter)' },
  { component: InstagramIcon, label: 'Instagram' },
  { component: LinkedInIcon, label: 'LinkedIn' }
];

function FooterBar() {
  const date = new Date();
  const year = date.getFullYear();
  console.log(year);
  return (
    <Container
      sx={{
        // backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        alignItems: "center",
        padding: { xs: "20px", sm: "80px 60px 60px" }
      }}
    >
      <Box
        sx={{
          // backgroundColor: "red",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          mb: { xs: "20px", sm: "30px" },
        }}
        >
        {/* Logo section */}
        <Box
          sx={{
            // border: "1px solid black",
            // borderRadius: "50%",
            height: "60px",
            width: "60px",
            backgroundImage:`url(${logo})`,
            backgroundSize: "contain",
            backgroundPosition:"center",
            backgroundRepeat: "no-repeat",
            // backgroundColor: "skyblue",
            mb:{xs:"20px",sm:"0px"}
          }}
        >
        </Box>

        {/* Quick links */}
        <Box
          sx={{
            // backgroundColor: "yellow",
            display: "flex",
            flexDirection: {xs:"column",sm:"row"},
            fontSize:{xs:"12px",sm:"16px"},
            gap: {sm:"20px"} // Adds spacing between links
          }}
        >
          <Typography>Features</Typography>
          <Typography>Packages</Typography>
          <Typography>Testimonials</Typography>
          <Typography>Blogs</Typography>
          <Typography>FAQ</Typography>
        </Box>

        {/* Social media icons */}
        <Box
          sx={{
            mt:{xs:"20px",sm:"0px"}
          }}
        >
          {socialMediaIcons.map((icon, index) => (
            <IconButton key={index} aria-label={icon.label}>
              <icon.component />
            </IconButton>
          ))}
        </Box>
      </Box>

      {/* Horizontal line */}
      <Box
        sx={{
          height: "1px",
          width: "80%",
          backgroundColor: "#000"
        }}
      ></Box>

      {/* Copyright or other footer content */}
      <Box mt={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © {year} Sat App. All rights reserved.
        </Typography>
        <Link to="https://blackmoon.com.np/" target="_blank" >
          <Typography variant="body2" color="textSecondary" textAlign="center">
              Developed By
          </Typography>
        </Link>
      </Box>
    </Container>
  );
}

export default FooterBar;
