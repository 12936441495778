import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import TestimonialsCardListing from "./testimonials.card.listing";
const Testimonials = ()=>{
    return(
            <Container 
                sx={{
                    height:"auto",
                    backgroundColor: "#ffffff",
                    padding: {xs:"20px 20px",md:"80px 40px",lg:"80px 0px"},
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box
                >
                    <Typography
                        variant="h3"
                        textAlign="center"
                        gutterBottom
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            width:"100%",
                            textTransform: "uppercase"
                            // backgroundColor: "red",
                        }}
                    >What Our Students Say</Typography>
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="p"
                        fontFamily="Times New Roman"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"14px",md:"20px"},
                            lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                            pl:{md:"20px"}
                        }}
                    >
                        Discover how Sat App has transformed the test preparation 
                        journey for many.
                    </Typography> 
                </Box>
                <TestimonialsCardListing />
            </Container>
    );
}
export default Testimonials;