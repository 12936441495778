import TestModels from '../../../assets/features_images/test_model.png';
import DraggableCalculator from '../../../assets/features_images/draggable_calculator.png';
import ResultTracking from '../../../assets/features_images/results_tracking.png';
import Hints from '../../../assets/features_images/hints.png';
import ScoreBoard from '../../../assets/features_images/score_history.png';

const FeaturesData =[
    { 
        id:1,
        title: 'Online Test Models', 
        image: TestModels, 
        description: `Simulate real exam conditions with our variety
                    of online test models,helping you practice effectively and manage your time better.
                        `,
        status:"Deployed" 
    },
    { 
        id:2,
        title: 'Draggable Calculator', 
        image: DraggableCalculator, 
        description: `Draggable calculator ease your calculation during test, 
                    you can drag as require and enhance your test experience.` ,
        status:"Deployed" 
    },
    { 
        id:3,
        title: 'Results Tracking', 
        image: ResultTracking, 
        description: `Track your progress over time with our detailed analytics, 
                    helping you understand your strengths and weaknesses.` ,
        status:"Deployed" 
    },
    { 
        id:4,
        title: 'Score History', 
        image: ScoreBoard, 
        description: `SAT adaptive nature challenge your participation within a test.
                        So, during your test score boared reflect all score.
                    ` ,
        status:"Development" 
    },
    { 
        id:5,
        title: 'Hints', 
        image: Hints, 
        description: `Shows hints and correct answer to particular questions. 
                        Where you can evaluate your answer.` ,
        status:"Development" 
    },
  ];
  export default FeaturesData;