import React from 'react';
import { Typography,Box } from "@mui/material";
import ResourcesData from "./resources.data";
const AdditionalResources =()=>{
    return(
        <Box
                color="#ffffff"
            >
                    <Typography
                        variant="h3"
                        // textAlign="center"
                        gutterBottom
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            width:"100%",
                            textTransform: "uppercase",
                            // backgroundColor: "red",
                            mt:{xs:"20px",sm:"40px"}
                        }}
                    >
                        Additional Resources
                    </Typography>
                    {
                        ResourcesData.map((data,index)=>(
                            <Typography
                                gutterBottom
                                variant="subtitle2"
                                component="p"
                                fontFamily="Gill Sans, sans-serif"
                                // textAlign="center"
                                key={index}
                                sx={{
                                    fontSize: {xs:"14px",md:"20px"},
                                    lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                                    pl:{md:"20px"},
                                    cursor: "pointer",
                                }}
                            >
                                {data.resource_title}
                            </Typography>
                        ))
                    }
            </Box>
    )
}

export default AdditionalResources;