import React from 'react';
import { Container } from '@mui/material';
import FeaturesIntro from '../components/features_pages/features.intro';
import Features from "../components/landing/features/features.component";
import ChooseSat from '../components/features_pages/choose.sat';
const FeaturesPage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // backgroundColor: "yellow",
                // padding : "80px"
            }}
        >
            <FeaturesIntro />
            <Features />
            <ChooseSat />
        </Container>
    )
}
export default FeaturesPage;