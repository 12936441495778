import ProfilePic1 from '../../../assets/testimonials/f_img1.jpg'
import ProfilePic2 from '../../../assets/testimonials/f_img3.jpg'
import ProfilePic3 from '../../../assets/testimonials/f_img4.jpg'
import ProfilePic4 from '../../../assets/testimonials/f_img7.jpg'
const TestimonialsData = [
    {
        id: 1,
        name: "Hari Narayan Chaudhary",
        testimonial: `Sat App has been a game changer for my test prep. 
                        The online tests are intuitive and the calculator is a great tool!`,
        pic: ProfilePic1,
        desig: "Student",
        rating: 4,
    },{
        id: 2,
        name: "Rajesh Shrestha",
        testimonial: `Our students have consistently 
                        achieved higher scores thanks to the tailored 
                        approach of Sat App.`,
        pic: ProfilePic2,
        desig: "Student",
        rating: 4.5,
        
    },{
        id: 3,
        name: "Santosh Bist",
        testimonial: `The resources provided by Sat App are invaluable. 
                The practice tests helped me feel prepared and confident.`,
        pic: ProfilePic4,
        desig: "Student",
        rating: 5,
    },{
        id: 4,
        name: "Ramit Kunwar",
        testimonial: `I was so confused about Sat Preparation. 
                    I was unknown about adaptive evaluation, messed up with pattern.
                    Now Sat application clear my doubts.`,
        pic: ProfilePic3,
        desig: "Student",
        rating: 3,
    },
]
export default TestimonialsData;