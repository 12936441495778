 const ChooseData =[
    {
        id: 1,
        title:`Why Choose Sat App for Your Test Prep?`,
        sub_title:`Sat App offers a comprehensive test preparation experience with 
                    features designed to enhance your learning and performance. From realistic test models to detailed 
                    results tracking, our app provides everything you need to succeed.`,
        included_list:[
            'Realistic online test models that simulate actual exam conditions.',
            'Convenient draggable calculator for seamless calculations.',
            'Detailed results tracking to monitor your progress and identify areas for improvement.'
        ]
    }
]
export default ChooseData;