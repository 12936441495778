const ResourceData = [
    {
        id:1,
        resource_title:`Digital SAT preparation`,
        description:``,
        link:'',
    },
    {
        id:2,
        resource_title:`Adaptive System`,
        description:``,
        link:'',
    },
    {
        id:3,
        resource_title:`How can you level up ?`,
        description:``,
        link:'',
    },
    {
        id:4,
        resource_title:`SAT cheat sheet`,
        description:``,
        link:'',
    },
]
export default ResourceData;