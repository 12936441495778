import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import Pic from '../../assets/features_images/choose_sat.png';
import chooseData from "./choose.data";
import ChooseIncludedList from "./choose_included_list";
const ChooseSat = ()=>{
    return(
        <Container 
            sx={{
                height:"auto",
                // backgroundColor: "yellow",
                padding: {xs:"20px 20px",md:"50px 40px",lg:"50px 0px"},
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Box
                sx={{
                    // backgroundColor: "red",
                    display: "flex",
                    flexDirection: {xs:"Column",sm:"row"},
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        backgroundImage:`url(${Pic})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: {xs:"100%",sm:"40%"},
                        height: {xs:"200px",sm:"340px"},
                        display: {xs:"none",sm:"block"}
                    }}
                    ></Box>
                <Box 
                    sx={{
                        width: {xs:"100%",sm:"60%"},
                        // backgroundColor:"green",
                        padding:{xs:"0px",sm:"0px 0px 0px 30px"}
                    }}
                >
                    {
                        chooseData.map((data,index)=>(
                            <Box key={index}>
                                <Typography
                                    variant="h3"
                                    textAlign="center"
                                    gutterBottom
                                    sx={{
                                        fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                                        fontWeight: "700",
                                        width:"100%",
                                        textTransform: "uppercase"
                                        // backgroundColor: "red",
                                    }}
                                >
                                    {data.title}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="subtitle2"
                                    component="p"
                                    fontFamily="Times New Roman"
                                    color="textSecondary"
                                    textAlign="justify"
                                    sx={{
                                        fontSize: {xs:"14px",md:"20px"},
                                        lineHeight: {xs:"18px",sm:"24px",md:"26px"},
                                        pl:{md:"20px"},
                                        mb:"20px"
                                    }}
                                >
                                    {data.sub_title}
                                </Typography>
                                {
                                    data.included_list.map((info,index)=>(
                                        <ChooseIncludedList key={index} data={info} />
                                    ))
                                }

                    </Box>
                        ))
                    }
                </Box>
            </Box>
        </Container>
    );
}
export default ChooseSat;