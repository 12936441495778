import { React,useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";

// ASSETS IMPORT
import logo from "../../../assets/sat_app_logo1.png";
import { HashLink } from "react-router-hash-link";
import './nav_bar.styles.css';

const navItems = [
  {
    navItem:"Home",
    itemLink: "/"
  },
  {
    navItem:"Features",
    itemLink: "/features"
  },
  {
    navItem:"Packages",
    itemLink: "/packages"
  },{
    navItem:"Testimonials",
    itemLink: "/testimonials"
  },
  {
    navItem:"Blogs",
    itemLink: "/blogs"
  },{
    navItem:"FaQ",
    itemLink: "/faq"
  },
];

function NavBar() {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [appBar,setAppBar] = useState(false); //for app-bar scroll effect 

  function toggleMobileDrawer() {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  }
  //this is for logo
  const renderLogo = (
    <img src={logo} alt="Inner Eye" style={{ width: "100%", height: "100%" }} />
  );
  //navigation items
  const renderItems = (
    <>
      {navItems?.map((item, index) => (
        <Typography 
          sx={{
            color: "#000000",
            // border: "1px solid black",
            // padding: "10px",
            margin: {xs:"10px",sm:"0px 10px"},
            backgroundcolor: "transparent",
            '&:hover':{
              // backgroundColor:"primary.main"
            },
            cursor: "pointer"
          }} 
            key={index}
        >
          
            <HashLink 
              style={{
                backgroundColor: "transparent",
                color: "#000000"
              }}
              to={item.itemLink}>{item.navItem}</HashLink>
          
        </Typography>
      ))}
    </>
  );
  //social media links
  const actions = (
      <Box 
        sx={{ 
          height: "auto",
          mx: {md: "0.5",lg: "0.9rem"}, 
          display: "flex",
          justifyContent:"center", 
          alignItems: "center",
          gap: 1,
          // border: "1px solid red"
          }}
      >
        <Button variant="contained" sx={{
          color: "accent.secondary",
          backgroundColor: "primary.second"
        }}>
          Take Test
        </Button>
        <Button variant="contained" sx={{
          color: "accent.secondary",
          backgroundColor: "primary.second"
        }}>
          Sign In
        </Button>
      </Box>
  );
  //handling scroll effect
  const changingOnScroll = () =>{
      if(window.scrollY >=62){
          setAppBar(true);
      }else{
          setAppBar(false);
      }
  }
  const bShadowOnScroll = "0px 2px 2px 0px rgba(28,36,49,0.3)";
  const bShadowOffScroll = "none";
  window.addEventListener('scroll',changingOnScroll)

  return (
    <AppBar position="sticky" 
        sx={{ 
          width:"100%",
          backgroundColor: "primary.main",
          top: 0 ,
          boxShadow:appBar?bShadowOnScroll:bShadowOffScroll
        }}>
      <Toolbar
        disableGutters
        className="apply--maxwidth"
        sx={{ width: "100%", py: "0" }}
      >
        {/* START MOBILE VIEW */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              // border: "1px solid black",
              // backgroundColor: "red",
              height: "60px",
              margin: "10px 20px",
              padding: "6px",
              maxWidth : "66px"
            }}
          >{renderLogo}</Box>
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={toggleMobileDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        {/* END MOBILE VIEW  */}

        {/* START DESKTOP VIEW */}
        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            display: { xs: "none", md: "flex" },
            // backgroundColor: "green",
            padding: "0px 60px"
          }}
        >
          <Box
            sx={{
              // border: "1px solid black",
              // backgroundColor: "red",
              height: "60px",
              margin: "10px 0px",
              // padding: "6px",
              // borderRadius: "50%",
              // boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) "
            }}
          >
            {renderLogo}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: {md:"0.5rem",lg: "1rem"},
              // border: "1px solid black"
            }}
          >
            {renderItems}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // border: "1px solid black"
            }}
          >
            {actions}
          </Box>
        </Box>
        {/* END DESKTOP VIEW */}
      </Toolbar>

      <Drawer
        anchor="right"
        open={isMobileDrawerOpen}
        onClose={() => setIsMobileDrawerOpen(false)}
        sx={{
          // width: 400,
          '& .MuiDrawer-paper': {
            width: 200,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box>
          {renderItems}
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: "50px"
        }}>
          {actions}
        </Box>
        
      </Drawer>
    </AppBar>
  );
}
export default NavBar;