import React, { useState, useEffect } from 'react';
import { Button, Radio, RadioGroup, FormControl, FormControlLabel, Typography, Box } from '@mui/material';

const dummyQuestions = [
    { id: 1, question: 'What is the capital of France?', options: ['Paris', 'London', 'Berlin', 'Rome'], answer: 'Paris' },
    { id: 2, question: 'What is the largest planet in our solar system?', options: ['Earth', 'Saturn', 'Jupiter', 'Uranus'], answer: 'Jupiter' },
    { id: 3, question: 'What is the chemical symbol for gold?', options: ['Ag', 'Au', 'Hg', 'Pb'], answer: 'Au' },
    { id: 4, question: 'Who painted the Mona Lisa?', options: ['Leonardo da Vinci', 'Michelangelo', 'Raphael', 'Caravaggio'], answer: 'Leonardo da Vinci' },
    { id: 5, question: 'What is the smallest country in the world?', options: ['Vatican City', 'Monaco', 'Nauru', 'Tuvalu'], answer: 'Vatican City' },
    { id: 6, question: 'What is the largest mammal on Earth?', options: ['Blue whale', 'Fin whale', 'Humpback whale', 'Sperm whale'], answer: 'Blue whale' },
    { id: 7, question: 'What is the highest mountain in the solar system?', options: ['Mount Everest', 'Olympus Mons', 'Kilimanjaro', 'Elbrus'], answer: 'Olympus Mons' },
    { id: 8, question: 'What is the deepest part of the ocean?', options: ['Mariana Trench', 'Challenger Deep', 'Tonga Trench', 'Kermadec Trench'], answer: 'Challenger Deep' },
    { id: 9, question: 'What is the largest living structure on Earth?', options: ['Great Barrier Reef', 'Amazon rainforest', 'Grand Canyon', 'Great Rift Valley'], answer: 'Great Barrier Reef' },
    { id: 10, question: 'What is the hottest planet in the solar system?', options: ['Mercury', 'Venus', 'Mars', 'Jupiter'], answer: 'Venus' },
    { id: 11, question: 'What is the farthest human-made object from Earth?', options: ['Voyager 1', 'Voyager 2', 'Pioneer 10', 'New Horizons'], answer: 'Voyager 1' },
    { id: 12, question: 'What is the largest star in the universe?', options: ['VY Canis Majoris', 'UY Scuti', 'RW Cephei', 'KY Cygni'], answer: 'VY Canis Majoris' },
    { id: 13, question: 'What is the fastest land animal?', options: ['Cheetah', 'Pronghorn', 'Thomson\'s gazelle', 'Springbok'], answer: 'Cheetah' },
    { id: 14, question: 'What is the largest waterfall in the world?', options: ['Victoria Falls', 'Iguazu Falls', 'Niagara Falls', 'Angel Falls'], answer: 'Victoria Falls' },
    { id: 15, question: 'What is the longest river in South America?', options: ['Amazon River', 'Parana River', 'Sao Francisco River', 'Magdalena River'], answer: 'Amazon River' },
    { id: 16, question: 'What is the highest mountain peak in North America?', options: ['Denali', 'Mount Logan', 'Mount Saint Elias', 'Popocatepetl'], answer: 'Denali' },
    { id: 17, question: 'What is the largest island in the Mediterranean Sea?', options: ['Sicily', 'Sardinia', 'Corsica', 'Crete'], answer: 'Sicily' },
    { id: 18, question: 'What is the longest mountain range in South America?', options: ['Andes', 'Rocky Mountains', 'Appalachian Mountains', 'Himalayan Mountains'], answer: 'Andes' },
    { id: 19, question: 'What is the world\'s largest desert?', options: ['Sahara', 'Gobi', 'Mojave', 'Atacama'], answer: 'Sahara' },
    { id: 20, question: 'What is the world\'s longest river?', options: ['Nile', 'Amazon', 'Yangtze', 'Mississippi'], answer: 'Nile' },
    { id: 21, question: 'What is the world\'s largest rainforest?', options: ['Amazon', 'Congo', 'Valdivian', 'Daintree'], answer: 'Amazon' },
    { id: 22, question: 'What is the world\'s largest coral reef system?', options: ['Great Barrier Reef', 'Red Sea Coral Reef', 'New Caledonia Barrier Reef', 'Belize Barrier Reef'], answer: 'Great Barrier Reef' },
  ];
  
// const dummyQuestions = Array.from({ length: 22 }, (_, index) => ({
//  id: index + 1,
//  question: `Question ${index + 1}: What is the correct answer?`,
//  options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
//  answer: 'Option 1' // Let's assume Option 1 is the correct answer for all
// }));


const QuizApp = () => {
 const [timeLeft, setTimeLeft] = useState(60); // 1 minute countdown
 const [selectedAnswers, setSelectedAnswers] = useState({});
 const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Current question index
 const [isTimeUp, setIsTimeUp] = useState(false);
 const [showResult, setShowResult] = useState(false);


 // Timer logic
 useEffect(() => {
   if (timeLeft > 0) {
     const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
     return () => clearTimeout(timerId);
   } else {
     setIsTimeUp(true);
   }
 }, [timeLeft]);


 // Handle option selection
 const handleOptionSelect = (questionId, option) => {
   setSelectedAnswers({ ...selectedAnswers, [questionId]: option });
   console.log(`Question ${questionId} selected option: ${option}`);
 };


 // Check if all questions are attempted
 const allQuestionsAttempted = Object.keys(selectedAnswers).length === dummyQuestions.length;

 // Calculate results (correct, incorrect, not attempted)
 const calculateResult = () => {
   let correct = 0, incorrect = 0, notAttempted = 0;

   dummyQuestions.forEach((q) => {
     const selected = selectedAnswers[q.id];
     console.log("selected :",selected);
     console.log('Correct Answer:',q.answer);
     if (!selected) {
       notAttempted += 1;
     } else if (selected === q.answer) {
       correct += 1;
     } else {
       incorrect += 1;
     }
   });


   return { correct, incorrect, notAttempted };
 };


 // Handle submit action to calculate result
 const handleSubmit = () => {
   setShowResult(true);
 };


 // Handle Next and Previous navigation
 const handleNext = () => {
   if (currentQuestionIndex < dummyQuestions.length - 1) {
     setCurrentQuestionIndex(currentQuestionIndex + 1);
   }
 };


 const handlePrevious = () => {
   if (currentQuestionIndex > 0) {
     setCurrentQuestionIndex(currentQuestionIndex - 1);
   }
 };


 // Render result after time is up or on submit
 if (showResult || isTimeUp) {
   const { correct, incorrect, notAttempted } = calculateResult();
   return (
     <Box textAlign="center" mt={4}>
       <Typography variant="h4">{isTimeUp ? 'Times Up!' : 'Quiz Submitted'}</Typography>
       <Typography variant="h6">Results:</Typography>
       <Typography>Correct Answers: {correct}</Typography>
       <Typography>Incorrect Answers: {incorrect}</Typography>
       <Typography>Not Attempted: {notAttempted}</Typography>
     </Box>
   );
 }


 // Get the current question
 const currentQuestion = dummyQuestions[currentQuestionIndex];


 return (
   <Box textAlign="center" mt={4}>
     <Typography variant="h5">Math Module</Typography>
     <Typography variant="subtitle1">Time Left: {timeLeft} seconds</Typography>


     <Box mt={2} mb={2}>
       <Typography variant="h6">{currentQuestion.id}.{currentQuestion.question}</Typography>


       <FormControl component="fieldset">
         <RadioGroup
           value={selectedAnswers[currentQuestion.id] || ''}
           onChange={(e) => handleOptionSelect(currentQuestion.id, e.target.value)}
         >
           {currentQuestion.options.map((option) => (
             <FormControlLabel
               key={option}
               value={option}
               control={<Radio />}
               label={option}
               disabled={!!selectedAnswers[currentQuestion.id]} // disable after selection
             />
           ))}
         </RadioGroup>
       </FormControl>
     </Box>


     {/* Previous and Next buttons */}
     <Box mt={2}>
       <Button
         variant="contained"
         color="primary"
         onClick={handlePrevious}
         disabled={currentQuestionIndex === 0}
       >
         Previous
       </Button>
       <Button
         variant="contained"
         color="primary"
         onClick={handleNext}
         disabled={currentQuestionIndex === dummyQuestions.length - 1}
         style={{ marginLeft: '10px' }}
       >
         Next
       </Button>
     </Box>


     {/* Show Submit button when all questions are attempted or time is up */}
     {(allQuestionsAttempted || isTimeUp) && (
       <Box mt={2}>
         <Button variant="contained" color="secondary" onClick={handleSubmit}>
           Submit
         </Button>
       </Box>
     )}
   </Box>
 );
};


export default QuizApp;