import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import TestimonialsCardPageListing from "./testimonials.card.page.listing";
const Testimonials = ()=>{
    return(
        <Container 
            sx={{
                height:"auto",
                // backgroundColor: "yellow",
                padding: {xs:"20px 20px",md:"50px 40px",lg:"50px 0px"},
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                color="#000000"
            >
                    <Typography
                        variant="h3"
                        textAlign="center"
                        gutterBottom
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            width:"100%",
                            textTransform: "uppercase"
                            // backgroundColor: "red",
                        }}
                    >What Our Students Say</Typography>
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="p"
                        fontFamily="Times New Roman"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"14px",md:"20px"},
                            lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                            pl:{md:"20px"}
                        }}
                    >
                        Discover how Sat App has transformed test 
                        preparation for students and consultancies alike.
                    </Typography>
            </Box>
            <TestimonialsCardPageListing />
            <Box>
            <Typography
                        variant="h3"
                        textAlign="center"
                        gutterBottom
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            width:"100%",
                            textTransform: "uppercase",
                            mt:"20px"
                            // backgroundColor: "red",
                        }}
                    >Why Testimonials Matter</Typography>
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="p"
                        fontFamily="Times New Roman"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"14px",md:"20px"},
                            lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                            pl:{md:"20px"}
                        }}
                    >
                        Testimonials provide valuable insights into the effectiveness of our test prep services. 
                        They help build trust and credibility, 
                        showing potential users the real benefits experienced by others.
                    </Typography>
            </Box>
        </Container>
    );
}
export default Testimonials;