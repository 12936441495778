import React from 'react'
import { Container } from "@mui/material"
import HeroSection from "../components/landing/hero/hero_section.component";
import Features from "../components/landing/features/features.component";
import Working from "../components/landing/working/working.component";
import Plans from "../components/landing/subscribtion_plans/plans.components";
import Testimonials from "../components/landing/testimonials/testimonials.component";
import Faq from "../components/landing/faq/faq.component";
import Articles from "../components/landing/articles/articles.components";
const Landingpage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // backgroundColor: "yellow",
                // padding : "80px"
            }}
        >
            <HeroSection />
            <Features />
            <Working />
            <Plans />
            <Testimonials />
            <Faq />
            <Articles />
        </Container>
    );
}
export default Landingpage;