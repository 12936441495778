import React from 'react';
import { Box, Button, Container, Typography } from "@mui/material";
import LoadWay from "./load_way";
import workingData from "./working.data";
const Working = ()=>{
    return(
            <Container 
                sx={{
                    height:"auto",
                    // backgroundColor: "#dedede",
                    padding: {xs:"20px 20px",md:"80px 40px",lg:"80px 0px"},
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="h3"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            textTransform: "uppercase",
                            // backgroundColor: "yellow"
                        }}
                    >
                        How It Works
                    </Typography>
                    {
                        workingData.map((data,index)=>(
                            <LoadWay key={index} working={data}/>
                        ))
                    }
                    <Typography variant="h3"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            textTransform: "uppercase",
                            mt:{xs:"30px",sm:"50px"}
                            // backgroundColor: "yellow"
                        }}
                    >
                        Ready to Get Started
                    </Typography>
                    <Typography variant="h6"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"14px",sm:"24px"},
                            fontWeight:{xs:"500",sm:"300"},
                            color:"rgba(0,0,0,0.7)"
                        }}
                        gutterBottom
                    >
                        Join Sat App today and take the first step towards acing your SATs.
                    </Typography>
                    <Button variant="contained"
                        sx={{
                            backgroundColor: "primary.second",
                            color: "white",
                            mt:"20px"
                        }}
                    >
                        Take Test
                    </Button>
                </Box>
            </Container>
    );
}
export default Working;