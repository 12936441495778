import React from 'react';
import { Box, Container } from "@mui/material";
import TitleAndContent from "./title_and_contnet";
import FeaturesCardListing from "./features_card_listing";
const Features = ()=>{
    return(
        <Container 
            sx={{
                height:"auto",
                // backgroundColor: "yellow",
                padding: {xs:"20px 20px",md:"50px 40px",lg:"50px 0px"},
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Box
            >
                <TitleAndContent />
                <FeaturesCardListing />
            </Box>
        </Container>
    );
}
export default Features;