const ArticlesData= [
    {
        id:1,
        articleTitle: `Top 5 Study Tips for SAT Success`,
        articleDesc: `Discover essential study tips that can help you ace the SAT.`,
        postedOn: '2024-05-12'
    },
    {
        id:2,
        articleTitle: `Understanding the SAT Scoring System`,
        articleDesc: `Learn how the SAT scoring system works and 
                        what it means for your college applications.`,
        postedOn: '2024-05-13'
    },
    {
        id:3,
        articleTitle: `The Benefits of Online Test Prep`,
        articleDesc: `Explore the advantages of using online platforms for SAT preparation.`,
        postedOn: '2024-07-12'
    },
    {
        id:4,
        articleTitle: `How to Create a Study Plan for the SAT`,
        articleDesc: `A step-by-step guide to creating an effective SAT study plan.`,
        postedOn: '2024-08-14'
    },
]
export default ArticlesData;