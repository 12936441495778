import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import ArticlesCard from "../landing/articles/articles.card";
import ArticlesData from "../landing/articles/articles.data";
import AdditionalResources from "./additional.resources";
const Articles = ()=>{
    return(
        <Container 
            sx={{
                height:"auto",
                // backgroundColor: "yellow",
                padding: {xs:"20px 20px",md:"50px 40px",lg:"50px 0px"},
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                color="#ffffff"
                sx={{
                    mb:{xs:"20px",sm:"40px"}
                }}

            >
                    <Typography
                        variant="h3"
                        textAlign="center"
                        gutterBottom
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            width:"100%",
                            textTransform: "uppercase"
                            // backgroundColor: "red",
                        }}
                    >Blog</Typography>
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="p"
                        fontFamily="Times New Roman"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"14px",md:"20px"},
                            lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                            pl:{md:"20px"}
                        }}
                    >
                        Welcome to the Sat App Blog! Here, you’ll find valuable insights, 
                        tips, and resources to help you excel in your SAT preparation. 
                        Stay tuned for the latest articles and updates.
                    </Typography>
            </Box>
            <Grid container spacing={4}
                        sx={{
                            justifyContent: "center"
                        }}
                    >
                        {
                            ArticlesData.map((data,index)=>(
                                <ArticlesCard articles={data} key={index} />
                            ))
                        }
            </Grid>
            <AdditionalResources />
        </Container>
    );
}
export default Articles;