import React from 'react';
import Grid  from "@mui/material/Grid2";
import TestimonialsData from '../landing/testimonials/testimonials.data';
import TestimonialsCardPage from "./testimonials.cards.page";
const TestimonialsCardPageListing = ()=>{
    console.log("Hello Hello")
    console.log("check:",TestimonialsData);
    return(
        <Grid container spacing={4}
            sx={{
                justifyContent: "center",
                height: "auto",
                py:"40px",
                // backgroundColor: "skyblue"
            }}
        >
            {
                TestimonialsData.map((data,index)=>(
                    <TestimonialsCardPage key={index} testimonials={data} />
                ))
            }
        </Grid>
    );
}
export default TestimonialsCardPageListing;