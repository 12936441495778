import React from 'react';
import { Typography ,Box, Button,Container} from "@mui/material";
const MissOut = ()=>{
    return(
        <Container 
            sx={{
                height:"auto",
                // backgroundColor: "yellow",
                padding: {xs:"20px 20px",md:"50px 40px",lg:"50px 0px"},
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Box
                sx={{
                    color:"#ffffff",
                    // backgroundColor: "red",
                    display: "flex",
                    justifyContent:"space-between",
                    alignItems: "center",
                    flexDirection: {xs:"column",sm:"row"},
                    mt: '40px'
                }}
            >
                <Typography
                    variant="h3"
                    // textAlign="center"
                    gutterBottom
                    sx={{
                        fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                        fontWeight: "700",
                        textTransform: "uppercase",
                        // backgroundColor: "yellow",
                    }}
                >
                    Don't Miss Out
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {xs:"column",sm:"row"},
                        alignItems:"center"
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor:"primary.second",
                            margin: {xs:"20px",sm:"0px 20px"},
                            color:"#ffffff"
                        }}
                    >Get Started</Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor:"primary.second",
                            color:"#ffffff"
                            // margin: {xs:"20px",sm:"0px 20px"}
                        }}
                    >Learn More</Button>
                </Box>
            </Box>
        </Container>
    )
}
export default MissOut;