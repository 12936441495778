import React from 'react';
import Grid  from "@mui/material/Grid2";
import TestimonialsData from './testimonials.data';
import TestimonialsCard from "./testimonials.card";
const TestimonialsCardListing = ()=>{
    return(
        <Grid container spacing={4}
            sx={{
                justifyContent: "center",
                height: "auto",
                py:"40px",
                // backgroundColor: "skyblue"
            }}
        >
            {
                TestimonialsData.map((data,index)=>(
                    <TestimonialsCard key={index} testimonials={data} />
                ))
            }
        </Grid>
    );
}
export default TestimonialsCardListing;