// src/AppProviderBasic.jsx
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import DashboardContent from './dashboard.content';
const NAVIGATION = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'calculator',
    title: 'Calculator',
    icon: <TimelineIcon />,
  },
  {
    segment: 'scoreboard',
    title: 'Scoreboard',
    icon: <TimelineIcon />,
  },
  {
    segment: 'progress',
    title: 'Progress',
    icon: <TimelineIcon />,
  },
];

const demoTheme = createTheme({
    components: {
      // MuiAppBar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: 'green',  
      //       height: "200px"
      //     },
      //   },
      // },
      MuiAppProvider: {
        styleOverrides: {
          brandingTitle: {
            backgroundColor: 'lightblue', // Custom default background for the title
            color: 'white',              // Custom text color for the title
            padding: '8px',              // Optional padding to make it look better
          },
        },
      },
    },
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { 
    light: true,
    dark: true
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DemoPageContent({ pathname }) {
  return (
    <Box
      sx={{
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        // backgroundColor: "yellow",
        height: "auto"
      }}
    >
      <Typography>Dashboard content for {pathname}</Typography>
      <DashboardContent />
    </Box>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardTabs(props) {
  const { window } = props;

  const [pathname, setPathname] = React.useState('/page');

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={demoTheme}
      window={demoWindow}
      branding={{
        logo: '',
        title: 'Santosh Bist',
      }}
      
    >
      {/* Pass the title prop here */}
      <DashboardLayout >
        <DemoPageContent pathname={pathname} />
      </DashboardLayout>
    </AppProvider>
  );
}

DashboardTabs.propTypes = {
  window: PropTypes.func,
};

export default DashboardTabs;
