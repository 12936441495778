import React from 'react';
import { Container } from '@mui/material';
import Faqs from '../components/faqs_pages/faqs';
const FaqPage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // backgroundColor: "skyblue",
                padding: {xs:"20px 20px",md:"80px 40px",lg:"80px 0px"},
            }}
        >
            <Faqs />
        </Container>
    )
}
export default FaqPage;