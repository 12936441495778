import React from 'react';
import Grid from '@mui/material/Grid2';
import {Card, CardContent, Typography,Box } from '@mui/material';
const ArticlesCard = ({articles})=>{
    console.log(articles)
    return(
        <Grid item xs={12} sm={3}>
              <Card
                sx={{
                    // maxWidth:"440px",
                    height: "auto",
                    backgroundColor: "rgba(0,0,0,1)",
                    padding: {sm:"20px"},
                    display : "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    border:"1px solid #ffffff"

                }}
              >
                <CardContent>
                  <Typography variant="h4" fontFamily="Roboto-Serif"
                    
                    sx={{
                        fontSize: {xs:"16px",sm:"26px"},
                        fontWeight: "600",
                        fontFamily: "Gill Sans, sans-serif",
                    }}
                  >
                        {articles.articleTitle}
                    </Typography>
                  <Typography variant="subtitle1" 
                  fontFamily="Gill Sans, sans-serif"
                        sx={{
                            fontSize: {xs:"12px"},
                            fontWeight: "400",
                            // color: "rgba(0,0,0,0.7)",

                        }}
                    >
                    {articles.postedOn}
                  </Typography>
                  <Typography variant="subtitle1"
                    sx={{
                        fontSize: {xs:"14px",sm:"16px"},
                        fontWeight: "600",
                        textAlign: "justify",
                        fontFamily: "cursive",
                    }}
                >
                    {
                      articles.articleDesc.slice(0,90)+('...')
                    }
                  </Typography>
                </CardContent>
                <Box 
                  sx={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems:"center",
                    cursor: "pointer"
                  }}
                >
                  <Typography>
                    Read More
                  </Typography>
                  <span>
                    &#8594;
                  </span>
                </Box>
              </Card>
        </Grid>
    )
}
export default ArticlesCard;