import React from "react";
import {Card,Box, CardContent, CardMedia, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2";
const FeaturesCard = ({features})=>{
    return(
        <Grid item xs={12} sm={3} md={4}>
              <Card
                sx={{
                    maxWidth:"340px"
                }}
              >
                <Box 
                    sx={{
                        height:"160px",
                        width: "100%",
                    }}
                >  
                    <CardMedia component="img" 
                        image={features.image} 
                        alt={features.title} 
                        sx={{
                            backgroundColor: "skyblue",
                            height: "100%",
                            width: "100%",
                            backgroundPosition: "Center",
                            backgroundSize: "cover",
                            objectFit: "cover",
                            // padding: "20px 0px"
                        }}
                    />
                </Box>

                <CardContent>
                  <Typography variant="h6" fontFamily="Roboto-Serif"
                    sx={{
                        fontSize: {xs:"16px",sm:"22px"},
                        fontWeight: "600"
                    }}
                  >
                        {features.title}
                    </Typography>
                  <Typography variant="body2" color="textSecondary" fontFamily="Times New Roman"
                    sx={{
                        fontSize: {xs:"12px",sm:"16px"}
                    }}
                >
                    {features.description}
                  </Typography>
                </CardContent>
              </Card>
        </Grid>
    )
}
export default FeaturesCard;