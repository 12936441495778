import React from 'react';
import { Box, Typography } from "@mui/material";
import { IconButton } from '@mui/material';
const LoadWay = ({working})=>{
    return(
        <Box
            sx={{
                paddingTop: {xs:"20px",sm:"30px"},
                // backgroundColor: "red",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
             <IconButton color="#dedede" aria-label="menu">
                <working.icon />
            </IconButton>
            <Typography variant="h6"
                textAlign="center"
                sx={{
                    fontSize: {xs:"14px",sm:"20px",md: "28px",lg:"34px"},
                    fontWeight:"600",
                }}
                gutterBottom
            >{working.title}</Typography>
            <Typography 
                variant="subtitle1"
                textAlign="center"
                color="rgba(0,0,0,0.7)"
                sx={{
                    fontSize: {xs:"12px",sm:"16px",md: "20px"},
                    fontWeight:"300",
                    fontFamily: "Times New Roman"
                }}
                
            >{working.short}</Typography>
        </Box>
    )
}
export default LoadWay;