import React from 'react';
import {Box,Typography,IconButton} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
const ChooseIncludedList = ({data})=>{
    return(
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                // backgroundColor:"blue",
                ml:{xs:"0px",sm:"20px"}
            }}
        >
            <IconButton
                disableRipple
                sx={{
                    // backgroundColor: "red",
                    cursor: "default",
                    height: "20px",
                    width: "20px",
                    mr:"6px"
                }}
            >
                <CheckIcon />
            </IconButton>
            <Typography
                variant='subtitle1'
                color='textSecondary'
                sx={{
                    fontSize: {xs:"14px"},
                }}
            >
                {data}
            </Typography>
        </Box>
    )
}
export default ChooseIncludedList;