import  React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

export default function AccordionTransition({faqs}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        square={true}
        sx={[
          expanded
            ? {
                '& .MuiAccordion-region': {
                  height: 'auto',
                },
                '& .MuiAccordionDetails-root': {
                  display: 'block',
                },
              }
            : {
                '& .MuiAccordion-region': {
                  height: 0,
                },
                '& .MuiAccordionDetails-root': {
                  display: 'none',
                },
              },
              {
                boxShadow: "none",
                backgroundColor:"transparent",
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid black",
                padding: "0px",
                '&:before': { 
                  display: 'none' }
              },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            // backgroundColor: "red"
            padding:"0px",
            
          }}
        >
          <Typography variant='h6'
            fontFamily= "Gill Sans, sans-serif"
            fontWeight="600"
          >
            {faqs.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography 
            variant='subtitle1'
            sx={{
              fontSize:{xs:"16px"},
              fontWeight: "500"
            }}
            fontFamily= "calibri"
          >
            {faqs.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
