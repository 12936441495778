const PlansData = [
    {
        id: 1,
        title: "Individual Student Plan",
        pricing: "2,500 / 3months ",
        sub_title: "Perfect for individual learners seeking comprehensive test prep.",
        included_list: [
            "Access to all online test models",
            "Draggable calculator",
            "Results tracking",
            "Monthly performance reports"
        ],
    },
    {
        id: 2,
        title: "Consultancy Package",
        pricing: "25,000 / 3months ",
        sub_title: "Ideal for consultancies managing multiple students.",
        included_list: [
            "Access to all online test models",
            "Access for up to 14 students",
            "Draggable calculator",
            "Results tracking",
            "Monthly performance reports",
            "Dedicated support"
        ]
    }
]
export default PlansData;