import React from 'react';
import { Container } from "@mui/material";
import StudentDashboardComponent from "../student_dashboard/student_dashboard.component";
const StudentDashboard = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // backgroundColor: "yellow",
                // padding : "80px"
            }}
        >
            <StudentDashboardComponent />
        </Container>
    );
}
export default StudentDashboard;