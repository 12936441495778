import React from 'react';
import { Typography ,Box, IconButton} from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
const SpecialOffers = ()=>{
    return(
        <Box
            sx={{
                color:"#ffffff",
                // backgroundColor: "red"
            }}
        >
            <Typography
                variant="h3"
                textAlign="center"
                gutterBottom
                sx={{
                    fontSize: {xs:"22px",sm:"28px",md:"34px",lg:"48px"},
                    fontWeight: "700",
                    width:"100%",
                    textTransform: "uppercase"
                    // backgroundColor: "red",
                }}
            >
                Special Offers
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs:"column",sm:"row"},
                    justifyContent: "space-evenly"
                }}
            >
                <Box>
                    <Typography
                        gutterBottom
                        variant="h6"
                        sx={{
                            fontSize:{xs:"18px",sm:"26px"},
                            fontWeight: "600"
                        }}
                    >
                        Individual Student Plan
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            // backgroundColor: "red"
                        }}
                    >
                        <IconButton
                        sx={{
                            color: "#ffffff",
                            height: "20px",
                            width: "20px",
                            // backgroundColor: "green",
                            mr:"6px"
                        }}
                        >
                            <ThumbUpIcon 
                                sx={{
                                    color: "#ffffff",
                                    height: "20px",
                                    width: "20px"
                                }}
                            />
                        </IconButton>
                        <Typography
                            gutterBottom
                            variant="subtitle1"
                            fontFamily="cursive"
                            sx={{
                                fontSize: {xs:"14px",sm:"20px"}
                            }}
                        >
                            Get 10% off for the first three months.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            fontSize:{xs:"18px",sm:"26px"},
                            fontWeight: "600"
                        }}
                    >
                        Consultancy Package
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            // backgroundColor: "red"
                        }}
                    >
                        <IconButton
                        sx={{
                            color: "#ffffff",
                            height: "20px",
                            width: "20px",
                            // backgroundColor: "green",
                            mr:"6px"
                        }}
                        >
                            <ThumbUpIcon 
                                sx={{
                                    color: "#ffffff",
                                    height: "20px",
                                    width: "20px"
                                }}
                            />
                        </IconButton>
                        <Typography
                            gutterBottom
                            variant="subtitle1"
                            fontFamily="cursive"
                            sx={{
                                fontSize: {xs:"14px",sm:"20px"}
                            }}
                        >
                            Sign Up for a year and save 15%.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default SpecialOffers;