import React from 'react';
import Grid from '@mui/material/Grid2';
import {Card, CardContent, Typography, CardMedia } from '@mui/material';
const TestimonialsCard = ({testimonials})=>{
    console.log(testimonials)
    return(
        <Grid item xs={12} sm={4}>
              <Card
                sx={{
                    maxWidth:"340px",
                    height: "auto",
                    backgroundColor: "primary",
                    padding: {sm:"20px"},
                    display : "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"

                }}
              >
                <CardMedia 
                    component="img" 
                    image={testimonials.pic} 
                    alt={testimonials.name}
                    sx={{
                        backgroundColor:"red",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        objectFit: "100%"
                    }}
                />
                <CardContent>
                  <Typography variant="h4" fontFamily="Roboto-Serif"
                    gutterBottom
                    sx={{
                        fontSize: {xs:"18px",sm:"26px"},
                        fontWeight: "600",
                        fontFamily: "Gill Sans, sans-serif",
                    }}
                  >
                        {testimonials.name}
                    </Typography>
                  <Typography variant="subtitle1" 
                  fontFamily="Gill Sans, sans-serif"
                        sx={{
                            fontSize: {xs:"14px",sm:"16px"},
                            fontWeight: "400",
                            color: "rgba(0,0,0,0.7)",

                        }}
                    >
                    {testimonials.desig}
                  </Typography>
                  <Typography variant="subtitle1"
                    sx={{
                        fontSize: {xs:"16px",sm:"20px"},
                        fontWeight: "600",
                        textAlign: "justify",
                        fontFamily: "cursive",
                    }}
                >
                    {testimonials.testimonial}
                  </Typography>
                 
                </CardContent>
              </Card>
        </Grid>
    )
}
export default TestimonialsCard;