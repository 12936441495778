const FaqData = [
    {
        id: 1,
        question: "What is Sat App?",
        answer: `Sat App is an online test preparation platform 
                    that offers tailored packages for students and consultancies.`,
    },{
        id: 2,
        question: "How do I sign up?",
        answer: `You can sign up through our website by selecting a package 
                and completing the online payment process.`,
    },{
        id: 3,
        question: "What features does the platform offer?",
        answer: `Our platform includes a test model that loads one question at a 
                time, a draggable calculator, and results tracking.`,
    },{
        id: 4,
        question: "Can I access the tests on multiple devices?",
        answer: `Yes, you can access your tests on any device 
                with an internet connection after logging in.`,
    },
    {
        id: 5,
        question: "What if I have more questions?",
        answer: `Feel free to reach out to our support 
                team via the contact page for any additional inquiries.`,
    },
]
export default FaqData;