import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./components/layout/navbar/navbar.components";
import FooterBar from "./components/layout/footer/footer.components";
function RootLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <FooterBar />
    </>
  );
}

export default RootLayout;