import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import FaqData from '../landing/faq/faq.data';
import AccordionDesign from '../landing/faq/accrodian.design';
import NeedSupport from "./need.support";
const Faqs = ()=>{
    return(
        <Container 
            sx={{
                height:"auto",
                // backgroundColor: "yellow",
                padding: {xs:"20px 20px",md:"50px 40px",lg:"50px 0px"},
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                // color="#ffffff"
                sx={{
                    mb:{xs:"20px",sm:"40px"}
                }}

            >
                    <Typography
                        variant="h3"
                        textAlign="center"
                        gutterBottom
                        sx={{
                            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                            fontWeight: "700",
                            width:"100%",
                            textTransform: "uppercase",
                            // backgroundColor: "red",
                        }}
                    >FAQs</Typography>
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="p"
                        fontFamily="Times New Roman"
                        textAlign="center"
                        sx={{
                            fontSize: {xs:"14px",md:"20px"},
                            lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                            pl:{md:"20px"}
                        }}
                    >
                        Find answers to your questions about our 
                        test prep services and how to maximize your learning experience.
                    </Typography>
            </Box>
            {
                FaqData.map((data,index)=>(
                    
                    <AccordionDesign faqs={data} key={index} />
                    ))
            }
            <NeedSupport />
        </Container>
    );
}
export default Faqs;