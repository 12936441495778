import React from 'react';
import { Container } from '@mui/material';
import Packages from '../components/packages_pages/packages_intro';
import PlansCardListing from '../components/landing/subscribtion_plans/plans_card_listing';
import SpecialOffers from '../components/packages_pages/special_offers';
import MissOut from '../components/packages_pages/miss_out';
const PackagesPage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                backgroundColor: "#000000",
                padding: {xs:"20px 20px",md:"80px 40px",lg:"80px 0px"},
            }}
        >
            <Packages />
            <PlansCardListing />
            <SpecialOffers />
            <MissOut />
        </Container>
    )
}
export default PackagesPage;