import React from 'react';
import { Box, Container } from "@mui/material";
import Grid from '@mui/material/Grid2';
import ArticlesFirstSection from "./first_section";
import ArticlesData from './articles.data';
import ArticlesCard from "./articles.card";
const Articles = ()=>{
    return(
        <Container maxWidth={false}
            sx={{
                backgroundColor: "#000000",
            }}
        >
            <Container 
                sx={{
                    height:"auto",
                    padding: {xs:"20px 20px",md:"80px 40px",lg:"80px 0px"},
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box color="white"
                    sx={{
                        // backgroundColor: "red",
                    }}
                >
                    <ArticlesFirstSection />
                    <Grid container spacing={4}
                        sx={{
                            justifyContent: "center"
                        }}
                    >
                        {
                            ArticlesData.map((data,index)=>(
                                <ArticlesCard articles={data} key={index} />
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
}
export default Articles;