import React from 'react';
import './App.css';
import RootLayout from './root_layout';
import LandingPage from './pages/landing.page';
import FeaturesPage from './pages/feature.page';
import PackagesPage from './pages/package.page';
import TestimonialsPage from './pages/testimonials.page';
import ArticlesPage from './pages/articles.page';
import FaqPage from './pages/faq.page';
import StudentDashboard from './pages/student_dashboard.page';
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  RouterProvider, 
  Route 
} from 'react-router-dom';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route>
          <Route path='/sdash' element={<StudentDashboard />} />
        </Route>
        <Route exact path='/' element={<RootLayout />}>
          <Route index element={<LandingPage />} />
          <Route path='/features' element={<FeaturesPage />} />
          <Route path='/packages' element={<PackagesPage />} />
          <Route path='/testimonials' element={<TestimonialsPage />} />
          <Route path='/blogs' element={<ArticlesPage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='*' element={<h1>404 - Page Not Found</h1>} />
        </Route>
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
