import React from 'react';
import Grid from '@mui/material/Grid2';
import {Card, CardContent, Typography,Button } from '@mui/material';
import IncludedList from './included_list';
const PlansCard = ({plans})=>{
    console.log(plans)
    return(
        <Grid item xs={12} sm={4}>
              <Card
                sx={{
                    maxWidth:"340px",
                    height: "auto",
                    backgroundColor: "primary",
                    padding: {sm:"20px"}
                }}
              >
                <CardContent>
                  <Typography variant="h4" fontFamily="Roboto-Serif"
                    gutterBottom
                    sx={{
                        fontSize: {xs:"16px",sm:"26px"},
                        fontWeight: "600"
                    }}
                  >
                        {plans.title}
                    </Typography>
                  <Typography variant="h5" fontFamily="Times New Roman"
                    sx={{
                        fontSize: {xs:"12px",sm:"18px"},
                        fontWeight: "600"
                    }}
                >
                    {plans.pricing}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1" fontFamily="Times New Roman"
                    sx={{
                        fontSize: {xs:"14px",sm:"18px"},
                        fontWeight: "500"
                    }}
                >
                    {plans.sub_title}
                  </Typography>
                 {
                    plans.included_list.map((data,index)=>(
                        <IncludedList data={data} />
                    ))
                 }
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: "primary.second",
                        color: "#ffffff",
                        mt:"16px",
                        fontWeight: "600"
                    }}
                >Select Plan</Button>
                </CardContent>
              </Card>
        </Grid>
    )
}
export default PlansCard;