import React from 'react';
import { Container } from "@mui/material";
import DashboardTabs from './tabs/dashboard.tabs';
const StudentDashboardComponent = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // backgroundColor: "red",
                // padding : "80px"
            }}
        >
            <DashboardTabs />
        </Container>
    );
}
export default StudentDashboardComponent;