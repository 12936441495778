import React from 'react';
import Grid from "@mui/material/Grid2";
import FeaturesData from './features.data';
import FeaturesCard from "./features_card";
const FeaturesCardListing = ()=>{
    return(
        <Grid container spacing={4}
        sx={{
            justifyContent: "center",
            height: "auto",
            pt:"40px",
            // backgroundColor: "skyblue"
          }}
        >
            {
                FeaturesData.map((data,index)=>(
                    <FeaturesCard features={data} key={index} />
                ))
            }
            
        </Grid>
    )
}
export default FeaturesCardListing;