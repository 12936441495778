import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
const WorkingData = [
    {
        id:1,
        title:"Sign Up",
        short: `Create an account as an individual student or through a 
            consultancy. Complete the sign-up process to get started.`,
        icon: PersonAddIcon
    },{
        id:2,
        title:"Test Access",
        short: `Log in to your account and access the test model. 
                Questions will load one at a time with four options to choose from.`,
        icon: LaptopChromebookIcon
    },{
        id:3,
        title:"Result Analysis",
        short: `After completing the test, analyze your results.
                 Use the draggable calculator to assist with your calculations.`,
        icon: TrendingUpIcon
    },
]
export default WorkingData;