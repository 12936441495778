import React from 'react';
import { Container } from '@mui/material';
import Articles from '../components/blogs_pages/articles';
const ArticlesPage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                backgroundColor: "#000000",
                padding: {xs:"20px 20px",md:"80px 40px",lg:"80px 0px"},
            }}
        >
            <Articles />
        </Container>
    )
}
export default ArticlesPage;