import React from 'react';
import { Box, Button,Typography } from "@mui/material";

const NeedSupport = ()=>{
    return(
        <Box
            sx={{
                // backgroundColor:"red",
                mt:{xs:'30px',sm:"60px"},
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h3"
                textAlign="center"
                gutterBottom
                sx={{
                    fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                    fontWeight: "700",
                    width:"100%",
                    textTransform: "uppercase",
                    // backgroundColor: "red",
                }}
            >Need More Help</Typography>
            <Typography
                gutterBottom
                variant="subtitle2"
                component="p"
                fontFamily="Times New Roman"
                textAlign="center"
                sx={{
                    fontSize: {xs:"14px",md:"20px"},
                    lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                    pl:{md:"20px"}
                }}
            >
                Contact us for further assistance with your queries.
            </Typography>
            <Button 
                sx={{
                    backgroundColor:"primary.second",
                    mt:"20px",
                }}
            >
                Get Support
            </Button>
        </Box>
    )
}
export default NeedSupport;