import React from 'react';
import {Box,Typography,Button} from '@mui/material';
const ArticlesFirstSection  = ()=>{
    return(
        <Box
            sx={{
                // backgroundColor: "red",
                display:"flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection:{xs:"column",sm:"row"},
                mb:{xs: "20px",sm:"30px"}
            }}
        >
            <Box>
                <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                        fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                        fontWeight: "700",
                        width:"100%",
                        textTransform: "uppercase"
                    }}
                >
                Latest Articles
                </Typography>
                <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="p"
                    fontFamily="Times New Roman"
                    sx={{
                        fontSize: {xs:"14px",md:"20px"},
                        lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                        // pl:{md:"20px"},
                        mb:"20px"
                    }}
                >
                Explore our recent posts on SAT preparation strategies and tips.

                </Typography>
            </Box>
            <Box>
                <Button sx={{
                    backgroundColor:"primary",
                    border: '2px solid #ffffff',
                    color: "#ffffff",
                    fontSize: {xs: "12px",sm: "16px"}
                }}>
                    Read More
                </Button>
            </Box>
        </Box>
    )
}
export default ArticlesFirstSection;