import React from 'react';
import { Box, Typography } from "@mui/material";
const TitleAndContent = () =>{
    return(
        <Box
            sx={{
                display: "flex",
                flexDirection: {xs:"column",md:"row"},
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor:"green",
                py:"20px"
            }}
            >
                <Typography 
                    variant="h3"
                    textAlign="center"
                    gutterBottom
                    sx={{
                        fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
                        fontWeight: "700",
                        width:"100%",
                        textTransform: "uppercase"
                        // backgroundColor: "red",
                    }}
                >Key Features</Typography>
                <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="p"
                    fontFamily="Times New Roman"
                    color="textSecondary"
                    sx={{
                        textAlign: "justify",
                        fontSize: {xs:"14px",md:"20px"},
                        lineHeight: {xs:"18px",sm:"26px",md:"28px"},
                        pl:{md:"20px"},
                    }}
                >
                    Discover the powerful tools and features that make 
                    Sat App the ultimate test preparation platform. 
                    From realistic online test models to a convenient 
                    draggable calculator and comprehensive results 
                    tracking, we provide everything you need to succeed.
                </Typography>
        </Box>
    )
}
export default TitleAndContent;