import React from 'react';
import Grid  from "@mui/material/Grid2";
import PlansData from './plans.data';
import PlansCard from "./plans.card";
const PlansCardListing = ()=>{
    return(
        <Grid container spacing={4}
            sx={{
                justifyContent: "center",
                height: "auto",
                py:"40px",
                // backgroundColor: "skyblue"
            }}
        >
            {
                PlansData.map((data,index)=>(
                    <PlansCard key={index} plans={data} />
                ))
            }
        </Grid>
    )
}
export default PlansCardListing;